<div class="page-container">
  <form #contactForm
    id="contact-form"
    (submit)="sendMessage($event)">
    <a class="material-icons close-btn" [routerLink]="'/'">close</a>
    <div id="contact-title">Message Justin</div>
    <div id="text-input-container">
      <div class="input-group">
        <div id="name-label">
          <label for="name-input">Name</label>
        </div>
        <input type="text"
          id="name-input"
          name="name"
          [(ngModel)]="contactService.name"
          autofocus
          required>
      </div>
      <div class="input-group">
        <div id="email-label">
          <label for="email-input">Email</label>
        </div>
        <input type="email"
          id="email-input"
          name="email"
          [(ngModel)]="contactService.email"
          required>
      </div>
    </div>

    <div class="input-group body-group">
      <div id="body-label">
        <label for="body-input">Message</label>
      </div>
      <textarea id="body-input"
        name="message"
        [(ngModel)]="contactService.message"
        required></textarea>
    </div>

    <div id="contact-action-container">
      <button type="submit" class="material-icons"
        id="contact-send-btn">send</button>
    </div>
  </form>
</div>
<div id="loading-container" *ngIf="loading">
  <app-loading></app-loading>
</div>
<app-dialog [position]="'fixed'"></app-dialog>
