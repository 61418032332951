import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { DialogComponent, DialogType } from '../dialog/dialog/dialog.component';
import { UtilService } from '../util.service';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('contactForm') contactForm!: ElementRef;
  @ViewChild(DialogComponent) dialog!: DialogComponent;
  loading = false;

  constructor(
    private apiService: ApiService,
    public contactService: ContactService,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void { }

  sendMessage(e: Event): void {
    e.preventDefault();
    if (!this.contactForm.nativeElement.checkValidity()) {
      this.contactForm.nativeElement.reportValidity();
    }

    this.loading = true;

    const data = {
      name: this.contactService.name,
      email: this.contactService.email,
      message: this.contactService.message,
    };

    this.apiService.post('/messages', data).pipe(
      catchError(this.handleError('post', []))
    ).subscribe(r => {
      console.log(r);
      this.dialog.action = () => {
        this.contactForm.nativeElement.reset();
        this.loading = false;
      };
      this.dialog.alert(DialogType.SUCCESS, 'Success', 'Message sent.');
    });
  }

  private handleError<T>(operation = 'operation', result?: T, errorKey?: string): (e: any) => Observable<T> {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(this.utilService.customStringify(error)); // log to console instead

      this.dialog.action = () => {
        this.loading = false;
      };
      this.dialog.alert(DialogType.ERROR, 'Error Sending Message', `${operation} failed: ${error.message}`);

      if (errorKey === null || errorKey === '') {
        errorKey = 'mostRecent';
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
