import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

const HTTP_OPTIONS: IRequestOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response',
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = 'https://api.hampton.website';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  get(request: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + request, HTTP_OPTIONS)
      .pipe(
        tap(result => this.log(`fetched ` + request + ` from db`)),
        catchError(this.handleError('get', []))
      );
  }

  // post(endPoint: string, body: object = {}): Observable<any> {
  //   console.log('ApiService posting to ', endPoint, ':', body);
  //   return this.http.post<any>(this.apiUrl + endPoint, body, HTTP_OPTIONS)
  //     .pipe(
  //       tap(result => this.log('POST ' + endPoint + ':\n' + JSON.stringify(result, null, 2))),
  //       catchError(this.handleError('post', []))
  //     );
  // }
  post(endPoint: string, body: object = {}): Observable<any> {
    console.log('ApiService posting to ', endPoint, ':', body);
    return this.http.post<any>(this.apiUrl + endPoint, body, HTTP_OPTIONS);
  }

  postForm(endPoint: string, form: FormData): Observable<any> {
    const options: IRequestOptions = {
      observe: 'response',
      withCredentials: true
    };

    return this.http.post<any>(this.apiUrl + endPoint, form, options)
      .pipe(
        catchError(this.handleErrorB)
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T, errorKey?: string): (e: any) => Observable<T> {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(this.customStringify(error)); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      if (errorKey === null || errorKey === '') {
        errorKey = 'mostRecent';
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private handleErrorB(error: HttpErrorResponse): any {
    let response: any = error;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      response = error.error.message;
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was ` + JSON.stringify(error.error)
      );
      // return throwError('Something bad happened; please try again later.');
      response = { error: error.status };
    }
    return of(response);
  }

  private log(message: string): void {
    console.log('DbService: ' + message);
  }

  private customStringify = (v: any) => {
    const cache = new Set();
    return JSON.stringify(v, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our set
        cache.add(value);
      }
      return value;
    }, 2);
  }
} // end ApiService

export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
