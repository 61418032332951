<div [ngClass]="classes" *ngIf="isShown()" [style.position]="position">
  <i [ngClass]="iconClasses">{{icon}}</i>
  <div class="rob dialog-title">{{title}}</div>
  <div *ngIf="!preformatted" class="rob dialog-body">{{body}}</div>
  <div *ngIf="preformatted" class="rob dialog-body"><pre>{{body}}</pre></div>
  <div class="rob dialog-footer">{{footer}}</div>
  <button class="rob dialog-action" (click)="onDialogAction()">{{actionText |
    uppercase}}</button>
  <button *ngIf="isConfirm" class="rob dialog-action"
    (click)="onDialogCancel()">{{dismissText | uppercase}}</button>
</div>
