<div #resume class="page-container">
  <!-- <div id="action-container" (click)="newDownload()"> -->
  <div id="action-container" (click)="download()">
    <img src="assets/img/pdf-download.png" id="download-btn">
  </div>

  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part header">
    <!-- Name and position -->
    <div class="name-container">
      <div class="name">Justin Hampton</div>
      <div class="position">Software Developer</div>
      <div id="name-decoration" class="decoration"></div>
    </div>

    <!-- Contact Details -->
    <div class="contact-details" id="contact-details">
      <div class="material-icons dropdown-btn"
        (click)="toggleContactDetails($event)">expand_more</div>
      <div class="contact-detail">
        justinemailone@gmail.com
        <span class="material-icons icon">email</span>
      </div>
      <div class="contact-detail">
        580 736 0632
        <span class="material-icons icon">phone</span>
      </div>
      <div class="contact-detail">
        Moore, OK
        <span class="material-icons icon">place</span>
      </div>
      <div class="contact-detail">
        <a href="www.linkedin.com/in/justin-a-hampton">
          <div>www.linkedin.com/in/justin-a-hampton</div>
          <span class="icon"><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAKFJREFUSIntl7sRgCAMhv94DsM0rsEaruEabGJn6ySx8PQ4BHxEiQVpuAtFPv48AAIAYx3jY5uGjmJ+KhE8B9GUCp4ydYA2dIQyfZ0idQUqwKEGSrYl8EcFcl3g723+2HC5o6JIgdR4TflfBTgLchVCXAPGOpYUrgjAD/wUQr0LKkAFqADqACqvYv8iK67AfmDmEVBIATPPAECgfl2h+zNaAHz3P+j/jh2yAAAAAElFTkSuQmCC"
              width="16px"></span>
        </a>
      </div>

      <!-- <div class="contact-detail">
        <a href="https://hampton.website">
          https://hampton.website
          <span class="material-icons icon">computer</span>
        </a>
      </div> -->
    </div>
  </div>

  <!-- Summary -->
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part">
    <div class="section-container single-col">
      <p>
        Detail oriented software developer with more than 5 years of experience
        in producing high quality code. In previous roles, worked in an agile
        development team and as a solo full-stack developer.
      </p>
    </div>
  </div>

  <!-- Experience -->
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part">
    <div class="body-seperator">
      <div class="hr body-seperator-left"></div>
      <div class="decoration decoration-small"></div>
      <h2>Experience</h2>
      <div class="decoration decoration-small"></div>
      <div class="hr body-seperator-right"></div>
    </div>

    <!-- <div class="section-container single-col"> -->
    <div>
      <div class="multi-col-header">
        <h3>Software Developer</h3>
        <p>June, 2019 - March, 2022</p>
      </div>
      <ul>
        <li>Diverse CTI, Oklahoma City, Oklahoma</li>
      </ul>

      <ul class="bulleted">
        <li>Developed Call Accounting, a web application
          integrated with an open source IP PBX (FreePBX), providing call
          history, statistics, data visualizations, and automated reporting for
          enterprise phone systems. The front-end was built with Angular and the
          backend utilized MySQL databases, PHP 5.6, and Apache server running
          on CentOS GNU/Linux.
        </li>

        <li>Created Phone Manager, a cross-platform telephone management
          application illiminating the need for users to be on premise to check
          voicemail, view call history, and change phone system settings. Used
          the Flutter framework and pre-existing designs and wireframes to
          implement functional UI.
        </li>
        <li>Modified and maintained legacy billing software written in C#. This
          included the implemention of an OAuth2 authentication workflow
          integrated with QuickBooks Online.
        </li>
        <li>Participated in project meetings with supervisors, clients, and UI
          designers.
        </li>
      </ul>
    </div>
    <!-- </div> -->

    <div class="subsection-seperator">
      <span class="decoration decoration-small"></span>
      <span class="decoration decoration-small"></span>
      <span class="decoration decoration-small"></span>
    </div>
  </div>
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part">
    <div class="multi-col-header">
      <h3>Product Developer</h3>
      <p>August, 2015 - May, 2019</p>
    </div>
    <ul>
      <li>Driven Analytics, Edmond, Oklahoma</li>
    </ul>

    <ul class="bulleted">
      <li>
        Provided support on the backend development of Maintain Mobile,
        an Android & iOS app which integrated bluetooth devices, Google Maps
        API, and vehicle and other data to take the guess work out of automotive
        maintenance and repairs.
      </li>
      <li>Worked within an agile development team to consistently develop
        and deliver high quality functional software after each sprint.
      </li>
      <li>Assisted with the development of a RESTful API service written in PHP
        and using the Phalcon API framework as well as Cassandra databases.
      </li>
      <li>Designed and developed internal tools using HTML, JavaScript, and CSS
        for data visualization and modification which increased productivity of
        company operations.
      </li>
    </ul>
  </div>


  <!-- Education -->
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part">
    <div class="body-seperator">
      <div class="hr body-seperator-left"></div>
      <div class="decoration decoration-small"></div>
      <h2>Education</h2>
      <div class="decoration decoration-small"></div>
      <div class="hr body-seperator-right"></div>
    </div>

    <div class="section-container">
      <div class="section-half">
        <h3>Univeristy of Central Oklahoma</h3>
        <ul>
          <li>Edmond, Oklahoma</li>
          <li>January, 2015 - August, 2022</li>
          <li>Bachelor of Science, Computer Science</li>
        </ul>
      </div>

      <div class="section-half">
        <h3>Oklahoma City Community College</h3>
        <ul>
          <li>Oklahoma City, Oklahoma</li>
          <li>August, 2012 - December, 2014</li>
          <li>Associate of Science, Computer Science</li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Skills -->
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <div class="pdf-part">
    <div class="body-seperator">
      <div class="hr body-seperator-left"></div>
      <div class="decoration decoration-small"></div>
      <h2>Skills</h2>
      <div class="decoration decoration-small"></div>
      <div class="hr body-seperator-right"></div>
    </div>
    <div class="section-container">
      <div class="section-half">
        <h3>Development</h3>
        <ul class="bulleted">
          <li>Backend development</li>
          <li>Web application development</li>
          <li>Multi-platform app development</li>
        </ul>
      </div>

      <div class="section-half">
        <h3>Technologies</h3>
        <ul class="bulleted">
          <li>Linux, Apache, PHP</li>
          <li>HTML, CSS, Javascript</li>
          <li>Angular web development framework</li>
          <li>Flutter application framework</li>
          <!-- <li>Phalcon API framework</li> -->
        </ul>
      </div>
    </div>
  </div>
  <!-- End PDF parts -->
</div>

<!-- loading spinner -->
<div id="loading-container" *ngIf="loading">
  <app-loading></app-loading>
</div>
