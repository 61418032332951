import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  // tslint:disable: variable-name
  public static readonly ERROR = 'error';
  public static readonly WARNING = 'warning';
  public static readonly SUCCESS = 'success';
  public static readonly CONFIRM = 'confirm';

  public static readonly ERROR_ICON = 'error_outline';
  public static readonly WARNING_ICON = 'warning';
  public static readonly SUCCESS_ICON = 'done';
  public static readonly CONFIRM_ICON = 'live_help';
  public static readonly TRY_AGAIN_BODY = 'Please try again. If you continue to receive this error contact the site administrator.';

  public preformatted = false;

  private _title = 'An error occurred.';
  private _body = DialogComponent.TRY_AGAIN_BODY;
  private _footer = '';
  private _actionText = 'OK';
  private _icon?: string;
  private _type = DialogType.NONE;
  private _show = false;
  private _isConfirm = false;
  private _dismissText = 'Cancel';
  private _position;

  // @Output() action: EventEmitter<any> = new EventEmitter();

  private _action!: () => any;
  private _onCancel!: () => any;

  get action(): () => any {
    return this._action;
  }

  set action(value: () => any) {
    this._action = value;
  }

  get onCancel(): () => any {
    return this._onCancel;
  }

  set onCancel(value: () => any) {
    this._onCancel = value;
  }

  get dismissText(): string {
    return this._dismissText;
  }

  set dismissText(value: string) {
    this._dismissText = value;
  }

  get isConfirm(): boolean {
    return this._isConfirm;
  }

  set isConfirm(value: boolean) {
    this._isConfirm = value;
  }

  get classes(): string[] {
    return [
      'dialog',
      'mat-elevation-z9',
      this.type,
    ];
  }

  get iconClasses(): string[] {
    return [
      'dialog-icon',
      'material-icons',
      this.type + '-icon'
    ];
  }

  get icon(): string {
    if (!this._icon) {
      switch (this.type) {
        case DialogType.ERROR:
          this._icon = DialogComponent.ERROR_ICON;
          break;
        case DialogType.WARNING:
          this._icon = DialogComponent.WARNING_ICON;
          break;
        case DialogType.SUCCESS:
          this._icon = DialogComponent.SUCCESS_ICON;
          break;
        case DialogType.CONFIRM:
          this._icon = DialogComponent.CONFIRM_ICON;
          break;
        default:
          this._icon = '';
          break;
      }
    }
    return this._icon;
  }
  @Input()
  set icon(value: string) {
    this._icon = value;
  }

  get title(): string {
    return this._title ? this._title : '';
  }
  @Input()
  set title(value: string) {
    this._title = value;
  }

  get body(): string {
    return this._body ? this._body : '';
  }
  @Input()
  set body(value: string) {
    this._body = value;
  }

  get footer(): string {
    return this._footer;
  }
  @Input()
  set footer(value: string) {
    this._footer = value;
  }

  get position(): string {
    return this._position;
  }
  @Input()
  set position(value: string) {
    this._position = value;
  }

  isShown(): boolean {
    return this._show;
  }

  dismiss(): void {
    this._show = false;
  }

  show(): void {
    this._show = true;
  }

  get actionText(): string {
    return this._actionText ? this._actionText : '';
  }

  @Input()
  set actionText(value: string) {
    this._actionText = value;
  }

  get type(): DialogType {
    return this._type ? this._type : DialogType.NONE;
  }

  @Input()
  set type(value: DialogType) {
    this._type = value;
    this._icon = '';
    const noop = this.icon;
  }

  alert(type: DialogType, title: string, body: string, confirm = false, preformatted = false): void {
    this.preformatted = preformatted;
    this.isConfirm = confirm;
    this.actionText = confirm ? this.actionText : 'ok';
    this.type = type;
    this.title = title;
    this.body = body;
    this.show();
  }

  confirm(title: string, body: string, affirmText?: string): void {
    if (affirmText != null) {
      this.actionText = affirmText;
    }
    this.alert(DialogType.CONFIRM, title, body, true);
  }

  onDialogAction(): void {
    if (typeof this._action === 'function') {
      this._action();
    }
    this.dismiss();
  }

  onDialogCancel(): void {
    if (typeof this.onCancel === 'function') {
      this.onCancel();
    }
    this.dismiss();
  }
}

export enum DialogType {
  NONE = '',
  ERROR = 'error_outline',
  WARNING = 'warning',
  SUCCESS = 'success',
  CONFIRM = 'confirm'
}
