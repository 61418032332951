import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  name = '';
  email = '';
  message = '';
  constructor() { }
}
