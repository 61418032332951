<div id="background"></div>

<div class="header">
  <div class="header-name">
    <div>Justin Hampton</div>
  </div>
  <ul class="menu">
    <li [ngClass]="{'selected-item': router.url === '/home'}"><a
        [routerLink]="'/home'">Home</a></li>
    <span class="menu-item-seperator">|</span>
    <li [ngClass]="{'selected-item': router.url === '/resume'}"><a
        [routerLink]="'/resume'">Resume</a></li>
    <span class="menu-item-seperator">|</span>
    <li [ngClass]="{'selected-item': router.url === '/contact'}"><a
        [routerLink]="'/contact'">Contact</a></li>
  </ul>
</div>

<div id="frame-container">
  <app-frame></app-frame>
</div>
