import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions, jsPDFOptions } from 'jspdf';
import { HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  @ViewChild('resume') resume!: ElementRef;
  loading = false;
  pdfPartCount = 0;
  pdfProcessedPartsCount = 0;
  pdfX = 0;
  pdfY = 0;
  paperWidthMm = 210; // a4 paper size is 210 x 297mm
  paperHeightMm = 297;
  px2mmRatio = 3.8;
  screenWidth = 0;
  screenHeight = 0;
  savedPdfUrl = 'https://hampton.website/resume.pdf';

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log(this.screenWidth, this.screenHeight);
  }

  async download(): Promise<void> {
    if (this.loading) {
      return;
    }
    this.loading = true;

    // If screen width is less than 800px, the html2pdf becomes distorted
    // so we'll just serve up a saved copy of the resume.
    if (this.screenWidth < 800) {
      this.downloadSavedPdf(this.savedPdfUrl);
      this.loading = false;
      return;
    }

    this.resume.nativeElement.classList.add('pdf');

    // jsPDF defaults to a4 size & mm units
    const pdfOptions: jsPDFOptions = { unit: 'mm' };
    const pdf = new jsPDF(pdfOptions);

    const parts = this.resume.nativeElement.querySelectorAll('.pdf-part');
    this.pdfPartCount = parts.length;

    // for (const part of parts) {
    for (let i = 0; i < parts.length; ++i) {
      const part = parts[i];
      const height = part.scrollHeight / 3.8;
      this.addPdfPart(pdf, part, this.paperWidthMm, height);

      // Wait til the part is added before attempting to add another one.
      while (this.pdfProcessedPartsCount < i + 1) {
        await new Promise(f => setTimeout(f, 10));
      }
    }
    this.awaitPdf(pdf);
  }

  addPdfPart(pdf: jsPDF, part: HTMLElement, width: number, height: number): void {
    html2canvas(part).then(canvas => {
      // Handle page break
      if (this.pdfY + height > this.paperHeightMm) {
        pdf.addPage();
        this.pdfY = 10;
      }

      const imageData = canvas.toDataURL('image/jpeg', 1.0);
      const imageOptions: ImageOptions = { imageData, x: this.pdfX, y: this.pdfY, width, height };
      pdf.addImage(imageOptions);
      ++this.pdfProcessedPartsCount;
      this.pdfY += height;
    });
  }

  awaitPdf(pdf: jsPDF): void {
    if (this.pdfProcessedPartsCount < this.pdfPartCount) {
      setTimeout(() => {
        this.awaitPdf(pdf);
      }, 100);
    } else {
      this.resume.nativeElement.classList.remove('pdf');
      this.pdfProcessedPartsCount = 0;
      this.pdfY = 0;
      this.loading = false;
      // window.open(pdf.output('bloburl').toString(), '_blank');
      const url = pdf.output('bloburl').toString();
      window.open(url);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'JustinHampton-resume.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  downloadSavedPdf(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe(r => {
      const blob = new Blob([r]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.target = '_blank';
      a.download = 'JustinHampton-resume.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  toggleContactDetails(e: Event): void {
    const details = this.resume.nativeElement.querySelector('#contact-details');
    if (details.style.height === 'revert') {
      details.style.height = null;
    } else {
      details.style.height = 'revert';
    }
  }
}
